import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import EntityBaseModule from '@/store/entity';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { tabsNav } from './entityTabs';
import userModule from '@/store/user';
import { addNewPartner, getPartnerById, savePartner } from '@/api/partners';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PartnerModel, PartnerResponseModel, PartnerTextFields } from '@/interfaces/partner.interface';
import PartnersModule from '@/store/partners/index';
import EntityModel from '@/store/partners/entityModel';
import ResponseHandlerStaticModule from '@/store/modules/responseHandlerStatic';
import { FormData } from '@/interfaces/shared';

export const MODULE_NAME = 'partnerEntity';

export enum PartnerType {
  Individual = 'individual',
  Legal = 'legal',
  SelfEmployed = 'selfEmployed',
}

export const PartnerTypeShortTitles: Record<string, PartnerType> = {
  ИП: PartnerType.Individual,
  ООО: PartnerType.Legal,
  Самозанятый: PartnerType.SelfEmployed,
};

export const PartnerTypeTitles: Record<string, string> = {
  ИП: 'Индивидуальный предприниматель',
  ООО: 'Юридическое лицо',
  Самозанятый: 'Самозанятый',
};

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PartnerEntityModule extends EntityBaseModule {
  model: PartnerModel;
  tabsNav: TabsNavInterface = {};

  constructor(module: PartnerEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Action({ rawError: true })
  initAdd() {
    this.resetInfo();
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    await this.getById(id);

    for (const tab of Object.keys(tabsNav)) {
      if (userModule.canUserRead(tabsNav[tab].pathName)) {
        this.tabsNav[tab] = tabsNav[tab];
      }
    }
  }

  @Action({ rawError: true })
  async getById(id: string) {
    try {
      this.SET_IS_LOADING(true);
      const result = await getPartnerById(id);

      this.setInfo(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response?.data?.message ?? error, type: 'fail' });
    } finally {
      this.SET_IS_LOADING(false);
    }
  }

  @Action({ rawError: true })
  setInfo(info: PartnerResponseModel) {
    PartnersModule.updateTitleEdit(`${info.legalName}, ${info.legalAddress}`);

    const type = PartnerTypeShortTitles[info.type] ?? '';
    this.context.commit('SET_MODEL_VALUE', { key: 'type', value: type });

    this.context.commit('SET_MODEL_VALUE', { key: 'legalName', value: info.legalName ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'legalAddress', value: info.legalAddress ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'actualAddress', value: info.actualAddress ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'region', value: info.geoRegion?.id ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: info.phone === '' ? '' : '7' + info.phone });
    this.context.commit('SET_MODEL_VALUE', { key: 'email', value: info.email ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'requiresNotification', value: info.requiresNotification ?? false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSupplier', value: info.isSupplier });
    this.context.commit('SET_MODEL_VALUE', { key: 'isAgent', value: info.isAgent });
    this.context.commit('SET_MODEL_VALUE', { key: 'directorFio', value: info.directorFio ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: info.inn ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'kpp', value: info.kpp ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'ogrn', value: info.ogrn ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: info.bank ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bankAccount', value: info.bankAccount ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bik', value: info.bik ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: info.isTest });
    this.context.commit('SET_MODEL_VALUE', { key: 'onDemandPaymentEnabled', value: info.onDemandPaymentEnabled });

    if (info.geoRegion) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'region',
        value: info.geoRegion.id.toString(),
      });
    }

    if (info.responsiblePerson) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsiblePerson',
        value: info.responsiblePerson.id.toString(),
      });
    }

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  resetInfo() {
    PartnersModule.updateTitleEdit('');

    this.context.commit('SET_MODEL_VALUE', { key: 'type', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'legalName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'legalAddress', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'actualAddress', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSupplier', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isAgent', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'directorFio', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'kpp', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'ogrn', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bankAccount', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bik', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'onDemandPaymentEnabled', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'region', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsiblePerson', value: '' });

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async addNew(params: { textFields?: PartnerTextFields }) {
    try {
      const result = await addNewPartner(params.textFields);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Партнер создан', type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', {
          key: 'isTest',
          value: (params.textFields as PartnerTextFields).isTest === '1',
        });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      if (error.response.data.type === 'bad_request') {
        ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; textFields?: PartnerTextFields }) {
    try {
      const result = await savePartner(params.id, params.textFields as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', {
          key: 'isTest',
          value: (params.textFields as PartnerTextFields).isTest === '1',
        });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      if (error.response.data.type === 'validation_error') {
        const errorFields = error.response.data.errors.fields;
        let message = '';
        for (const field of Object.values(errorFields)) {
          message += `${field} <br/>`;
        }
        ResponseHandlerStaticModule.showNotify({ content: message, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }
}

export default getModule(PartnerEntityModule);
