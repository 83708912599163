import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  profile: {
    id: 'profile',
    name: 'Профиль',
    template: 'profile',
    pathName: 'partner_edit',
    icon: 'profile',
    iconSize: '15',
  },
  shops: {
    id: 'shops',
    name: 'Объекты',
    template: 'shops',
    pathName: 'partner_shops',
    icon: 'store',
    iconSize: '14',
  },
  logs: {
    id: 'logs',
    name: 'Логи',
    template: 'logs',
    pathName: 'partner_logs',
    icon: 'log',
    iconSize: '14',
  },
};
