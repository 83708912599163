/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class PartnersFilter {
  filterHandlers = {
    update: 'partnersFilterModule/updateFilter',
    reset: 'partnersFilterModule/resetFilter',
    resetState: 'partnersFilterModule/resetFilterState',
    removeSelected: 'partnersFilterModule/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('name');
    filter.setPlaceholder('name', 'По названию');
    filter.setName('name', 'search_fullName');
    filter.setPreviewName('name', 'Название');
    filter.setClassName('name', 'col-1-row-1');
    filter.setIconClassName('name', 'icon-partners');
    filter.setAction('name', 'partnersFilterModule/updateSearch');

    this.filterModel = filter.filterModel;
  }
}
