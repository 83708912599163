/**
 * Partner shops store.
 *
 */

import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { tabsNav } from './../entityTabs';

import { prepareList } from '@/lib/Shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';

export const MODULE_NAME = 'partnerShops';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { strings } from '@/lib/stringConst';
import { getShopsListByPartnerId } from '@/api/partners';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PartnerShopsModule extends PageBaseModule {
  partnerId = '';
  tabsNav = tabsNav;
  dataLoaded = false;

  constructor(module: PartnerShopsModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'partnerShops/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  SET_DATA_LOADED(value: boolean) {
    this.dataLoaded = value;
  }

  @Mutation
  SET_PARTNER_ID(id: string): void {
    this.partnerId = id;
  }

  @Mutation
  SET_SORT(sorts: PageSort): void {
    this.pageSettings.sort = sorts;
    window.localStorage.partnerShopsSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async initListById(partnerId: string): Promise<void> {
    await this.context.commit('SET_PARTNER_ID', partnerId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getListById(partnerId);
  }

  @Action({ rawError: true })
  async getListById(partnerId: string): Promise<void> {
    this.context.commit('SET_DATA_LOADED', false);

    try {
      let sort = '';
      const sortData: Record<string, { key: string; sort: string }> = window.localStorage.partnerShopsSort
        ? JSON.parse(window.localStorage.partnerShopsSort)
        : this.pageSettings.sort;

      let index: string;
      let item: { key: string; sort: string };
      for ([index, item] of Object.entries(sortData)) {
        sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
      }
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getShopsListByPartnerId(
        this.pageSettings.pageCurrent,
        '&filters[0][id]=partner&filters[0][value]=' + partnerId,
        itemsQuery,
        sort
      );
      await this.setList(result.table);

      this.context.commit('SET_DATA_LOADED', true);
    } catch (error) {
      if (error.response?.data?.errors) {
        ResponseHandlerModule.showNotify({ message: error.response?.data?.errors, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getList(): Promise<void> {
    await this.getListById(this.partnerId);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListById(this.partnerId);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    this.context.commit('SET_SORT', sortProcessed);
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  setList(table: TableInterface) {
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { sort }));
  }
}

export default getModule(PartnerShopsModule);
