
import { defineComponent } from 'vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import PartnerEntityModule from '@/store/partners/entity';
import PartnersModule from '@/store/partners';
import PartnerShopsModule from '@/store/partners/shops';

import AppTable from '@/components/ui/table/Table.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextPhone from '@/components/table-items/TextPhone.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { GuiLoader } from '@library/gigant_ui';

export default defineComponent({
  name: 'PartnerShops',
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    StatusActive,
    TextPhone,
    TextDatetime,
    AppTable,
    TitleReturn,
    TabsNav,
  },
  computed: {
    settings() {
      return PartnerShopsModule.pageSettings;
    },
    table() {
      return PartnerShopsModule.pageSettings.table;
    },
    titlePage() {
      return PartnersModule.pageSettings.titleEdit;
    },
    tabsNav() {
      return PartnerEntityModule.tabsNav;
    },
    currentTab() {
      return PartnerEntityModule.tabsNav.shops?.id;
    },
  },
  methods: {
    returnBefore() {
      this.$router.push({ name: 'partners' });
    },
    selectAmount(value: string) {
      PartnerShopsModule.updatePageAmountItems(value);
    },
    async clearSort() {
      PartnerShopsModule.clearSort();
      await PartnerShopsModule.initListById(this.$route.params.partnerId as string);
    },
    async sort(header: { id: string; sort: { value: string } }) {
      await PartnerShopsModule.sort({ field: header.id, sort: header.sort.value || '' });
      await PartnerShopsModule.initListById(this.$route.params.partnerId as string);
    },
  },
  mounted() {
    PartnerEntityModule.initEdit(this.$route.params.partnerId as string);
    PartnerShopsModule.initListById(this.$route.params.partnerId as string);
  },
});
