import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from '@/store/partners/filter';
import { getPartnersList } from '@/api/partners';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/Employees';
import { FilterHandlers } from '@/lib/layouts/page/filter.interface';
import FilterModule from '@/store/filter';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { strings } from '@/lib/stringConst';

/**
 * Partners store
 */
@Module({ dynamic: true, store, name: 'partnersFilterModule', namespaced: true })
class PartnersFilterModule extends FilterModule {
  constructor(module: PartnersFilterModule) {
    super(module);
  }
}

export const MODULE_NAME = 'partners';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PartnersModule extends PageBaseModule {
  filter: { filterHandlers: FilterHandlers; filterModel: {} };
  filterModule: FilterModule;

  constructor(module: PartnersModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Партнёры');
    page.setTitleAdd('Добавление  партнёра');
    page.values.actionPagination = 'partners/updatePage';
    this.pageSettings = page.values;

    this.filter = new filterModel();
    this.filterModule = getModule(PartnersFilterModule);
    this.filterModule.setFilterName('employeesFilter');
    this.filterModule.setTemplateClassName('template-lg');
    this.filterModule.setBtnClassName('col-1-row-8');
    this.filterModule.setFilterModel(this.filter.filterModel);
    this.filterModule.setFilterHandlers(this.filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.partnersSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.partnersSort ? JSON.parse(window.localStorage.partnersSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filterModule.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      let sort = '';
      let index: string;
      let item: { key: string; sort: string };
      for ([index, item] of Object.entries(this.pageSettings.sort)) {
        sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
      }
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);

      const filter = this.filterModule.filterSettings.filter;
      const result = await getPartnersList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    await this.getSettings();
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { sort }));
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }
}

export default getModule(PartnersModule);
