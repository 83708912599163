import { PartnerModel } from '@/interfaces/partner.interface';

export default class EntityModel {
  model: PartnerModel;

  constructor() {
    this.model = {
      type: '',
      legalName: '',
      legalAddress: '',
      actualAddress: '',
      region: '',
      responsiblePerson: '',
      regionCode: '',
      phone: '',
      email: '',
      requiresNotification: false,
      isSupplier: false,
      isAgent: false,
      directorFio: '',
      inn: '',
      kpp: '',
      ogrn: '',
      bank: '',
      bankAccount: '',
      bik: '',
      isTest: false,
      onDemandPaymentEnabled: false,
    };
  }
}
